.draggable-block {
    max-width: 800px;
    margin: 0 auto;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;

        .block-actions {
            opacity: 1;
            pointer-events: initial;
        }
    }

    .card {
        .card-body {
            padding-left: 40px;
            padding-right: 0;
        }

        .header-and-handle {
            display: flex;
            align-items: center;
            border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
            
            .accordion-header {
                position: relative;
                flex-grow: 1;
                padding: $spacer;
                cursor: pointer;
                border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: var(--bs-gray-100);
                }
            
                .accordian-caret {
                    font-size: 1em;
                    transition: transform 0.2s ease-in-out;
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: $spacer;
                }
            }

            &.expanded {
                .accordian-caret {
                    transform: rotate(-180deg);
                }
            }

            &.collapsed {
                // border-bottom: 0;
                .accordian-caret {
                    transform: rotate(0deg);
                }
            }
            
        }

        .field-handle {
            cursor: grab;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: $spacer;
            padding-bottom: $spacer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: var(--bs-gray-100);
            }

            &.dragging {
                background-color: var(--bs-gray-200);
            }
        }

        .card-title,
        .card-text {
            padding-left: $spacer;
            padding-right: $spacer;
        }

        .block-handle {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center; 
            width: 40px;
            transition: background-color 0.2s ease-in-out;
            border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

            &:hover {
                background-color: var(--bs-gray-100);
            }
        }

        $block-toggle-switch-width: 46px;

        .custom-switch {
            cursor: pointer;
        }

        .custom-switch .form-check-input {
            margin-top: 0;
            width: $block-toggle-switch-width;
            height: calc($block-toggle-switch-width / 2);
        }
        
        .custom-switch .form-check-input::before {
            width: $block-toggle-switch-width;
            height: calc($block-toggle-switch-width / 2);
        }
        
        .custom-switch .form-check-input::after {
            width: calc($block-toggle-switch-width / 2);
            height: calc($block-toggle-switch-width / 2);
        }
    }
}