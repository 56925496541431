.underline-input {
    border: none;
    border-bottom: 1px solid #b6bcc1;
    color: var(--bs-heading-color);
    border-radius: 0;
    background: transparent;
    min-width: 200px;
    max-width: 600px;
    display: inline-block;
    font-size: $h1-font-size;
    font-weight: $headings-font-weight;
    &:focus {
        background: transparent;
        outline: none;
        box-shadow: none;
        border-color: var(--bs-primary);
    }
}

.ghost-input {
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;
    font-size: $h1-font-size;
    font-weight: $headings-font-weight;
    white-space: pre;
    position: absolute;
    visibility: hidden;
}

// .handoff-title-input {
//     input, label {
//         font-size: $h1-font-size;
//         font-weight: $headings-font-weight;
//     }
// }

::selection {
    background: #8d91f3;
    color: white;
  }