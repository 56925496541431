.navbar {
    .handoff-title {
        cursor: pointer;
        border-radius: var(--bs-border-radius);
        border: 1px solid transparent;
        transition: background-color, border 0.2s ease-in-out;

        &:not(.editing) {
            &:hover {
                background-color: var(--bs-gray-100);
                border: 1px solid var(--bs-gray-300);
            }
        }

        .editiable-title {
            border: 1px solid transparent;
            border-radius: var(--bs-border-radius);
            padding: 4px 8px;
            margin: 0;

            height: 36px;
            line-height: 26px;

            &[contenteditable="true"] {
                cursor: text;
                border: 1px solid var(--bs-gray-300);

                &:focus {
                    outline: none;
                    border-color: var(--bs-primary);
                    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
                }
            }
        }

        .editiable-title-button {
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: var(--bs-border-radius);
            padding: 0;
            margin: 0;
            width: 36px;
            text-align: center;
            height: 36px;
            line-height: 36px;
        }
    }
}
